/* Header stylings */
.activepage {

    color: #225b97 !important;
}

.headercenter {
    justify-content: center;
}

.headerright {

    justify-content: flex-end;
    padding-right: 50px;
    padding-top: 20px !important;
}
