body {

    font-family: 'Montserrat', sans-serif;
    background-color: white;
    color: black;
    margin: 0px;
}

a {

    color: #225b97;
    text-decoration: none;
}

a:visited {

    color: #225b97;
    text-decoration: none;
}

a:hover {

    color: #2e6bac;
}

/* scss for the App */
.MuiButton-label {

    color: white;
}

.MuiButton-label {

    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}

.MuiInput-root {
    // border-bottom: 1px solid white !important;
    width: 100%;
}

.MuiInputLabel-root {

    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

.MuiButton-text {

    font-family: 'Montserrat', sans-serif !important;
    font-weight: 700 !important;
    color: white !important;
}

.MuiButton-text:hover {

    color: #2e6bac !important;
}
.sectionheader {

    margin: auto;
    border: 1px solid #225b97;
    color: #225b97;
    padding: 10px;
    margin-top: 100px;
    margin-bottom: 40px;
    font-weight: bold;
    font-size: 14px;
}

