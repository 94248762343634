/* reel stylings */

.reelheader {

    background-color: black;
    padding-bottom: 40px;
}

.reelspacer {

    padding-bottom: 40px;
}

.copy {

    margin-bottom: 10px;
    // font-weight: 600;
    font-size: 14px;
    line-height: 21px;
}

.preamble {

    margin-bottom: 30px;
}
