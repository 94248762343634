/* styling for home */
@import "~video-react/styles/scss/video-react"; // or import scss

.whitelink {
    color: white;
    text-decoration: none;
}

.whitelink:visited {

    color: white;
    text-decoration: none;
}

.whitelink:hover {

    color: #2e6bac;
    text-decoration: none;
}

.logoimg {

    width: 250px;
}

// .headermovie {

//     width: 100%;
//     // height: 660px;
// }

.headermovie {

    width: 100%;
    max-height: 600px !important;
}

.headermovie:focus {

    outline-width: 0px !important;
    outline-color: transparent !important;
}

.video-react {

    background-color: transparent;
    outline: none;
}

.video-react:focus {

    outline-width: 0px;
}

.video-react-video {

    width: 100%;
    max-height: 600px;
    background-color: white;
}

.video-react-video:focus {

    outline-width: 0px;
}

.header > * {

    position: absolute;
}

.headerparent {

    position: relative;
    height: 600px;
}

@media screen and (max-width: 1600px) {

    .headerparent {

        padding-bottom: 40.25%;
        height: inherit;
    }
}

.pageheader {

    // position: absolute;
    // position: element(#headermovie);
    // top: 0;
    // margin: auto;
    padding-top: 20px;
    font-weight: bold;
    color: white;
    // transform: translate(0px, -550px);
    // max-width: 1500px;
    // width: min(1450px, 100%);
    // margin-left: max(0px, calc(100% - 1450px)/2);
    // margin-right: max(0px, calc(100% - 1450px)/2);
    width: 100%;
    max-width: 1600px;
}

@media not screen and (max-width: 1450px) {

    .pageheader {

        width: 1450px;
        margin-left: calc((100% - 1450px) / 2);
        margin-right: calc((100% - 1450px) / 2);
    }
}

.headerimg {

    width: 275px;
    padding-top: 1em;
    margin: auto;
}

.headerrole {

    font-size: 16px;
    font-weight: 600;
    letter-spacing: 4px;
}

.headercontact {

    margin-left: 40px;
    margin-top: 20px;
    font-weight: 600;
    font-size: 14px;
}

.headerbuttons {

    margin-right: 40px;
    margin-top: 15px;
    margin-left: auto;
}

.headerbutton {

    margin-left: 20px !important;
}

.headerbuttonlabel {

    color: white;
}

.headerbuttonlabel:hover {

    color: #2e6bac;
}

.headshotimg {

    width: 250px;
    height: 260px;
    object-fit: cover;
    // margin-top: 1em;
    // margin-left: 1em;
    margin-right: 1.25em;
    margin-bottom: 0.25em;
    float: left;
}

.MuiCardContent-root {

    background-color: white;
    color: black;
    // font-weight: 600;
    line-height: 1.25em;
}

.MuiCard-root {

    // background-color: black !important;
    box-shadow: none !important;
}

.downgrid {

    position: absolute;
}

.sectionleft {

    border-right: 1px solid black;
    padding-right: 20px;
    text-align: right;
    // width: 300px;
}

.sectionright {

    padding-left: 20px;
}

.sectionsubheading {

    color: #225b97;
    font-weight: bold;
    font-size: 14px;
    padding-bottom: 10px;
}

.skill {

    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 600;
    font-size: 14px;
}

.projectrole {
    color: #225b97;
    font-weight: 600;
    font-size: 14px;
}

.project {

    // color: white;
    font-weight: 600;
    font-style: italic;
    padding-bottom: 20px;
    font-size: 14px;
}

.projectdescription {
   
    // color: white;
    // font-weight: 600;
    padding-bottom: 20px;
    font-size: 14px;
    line-height: 21px;
}

.development {

    // color: white;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
}

.award {

    // color: white;
    font-weight: 600;
    padding-bottom: 20px;
    font-size: 14px;
}

.patent {

    // color: white;
    font-weight: 600;
    padding-bottom: 20px;
    font-size: 14px;
}

.engagement {

    // color: white;
    font-weight: 600;
    padding-bottom: 20px;
    font-size: 14px;
}

.footer {

    // font-weight: 600;
    padding-top: 80px;
    font-size: 14px;
}

.footeritem {

    padding-bottom: 40px;
}

.footerbuttonlabel {

    font-weight: 600;
    color: black !important;
}

.footerlink {

    font-weight: bold;
}

.projecttitle {
    color: #225b97;
    font-weight: 600;
}

.headermobile {

    padding-top: 15px;
    padding-bottom: 10px;
    background-color: black;
    color: white;
}