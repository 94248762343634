/* contact stylings */

.contactheader {

    background-color: black;
    padding-bottom: 40px;
}

.copy {

    margin-bottom: 10px;
    // font-weight: 600;
    font-size: 14px;
    line-height: 21px;
}

.preamble {

    margin-bottom: 30px;
}

.MuiFormControl-root {

    width: 100%;
}

.MuiFormLabel-root {

    color: #225b97 !important;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 600 !important;
    font-size: 14px !important;
}

.MuiInputBase-root {

    // color: white !important;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 600 !important;
    font-size: 18px !important;
}

.MuiFormControl-root {

    margin-bottom: 40px !important;
}

#senditbutton {

    background-color: #225b97;
    border-radius: 20px;
    padding-left: 15px;
    padding-right: 15px;
}

#senditbutton:hover {

    background-color: #2e6bac;
    color: white;
}

.senditlabel {

    color: white;
}

.senditlabel:hover {

    color: white;
}

.contactcontent {

    margin-bottom: 50px;
}
